import { CiCreditCard1 } from "react-icons/ci";
import { TbBrandPaypal } from "react-icons/tb";
import React, { useContext, useEffect, useState } from "react";
import "./CheckoutV2.scss";
import { IoArrowBackOutline } from "react-icons/io5";
import ReactInputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import { returnPlan } from "./functions";
import { UserContext } from "../../context/UserContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/FirebaseConfig";
import { ICard } from "../../interfaces/ICard";
import { powerCoach } from "../../interfaces/PowerCoaches";
import {
	addPaymentMethod,
	createUserCollection,
	getPowerCoachSubByUser,
	getUserByEmail,
	retrieveUserSubs,
	updatePowerCoachCount,
	updateUserInfo,
	updateUserPowerCoachSubscription,
} from "../../services/UserService";
import { IPlan } from "../../interfaces/ICheckout";
import { getPowerCoachById } from "../../services/PowerCoachService";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { AlertModal } from "../../components/alertModal/AlertModal";
import { cardErrors } from "../../utilis/functions/Checkout";
import { Spinner } from "../../components/spinner/Spinner";
import { testimonies_array } from "../../utils/arrays";
import { getUser } from "../../services/ProfileService";
import { useUI } from "../../context/UIContext";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
const left_form = "/assets/redesign/checkout/left-form.png";
const left_back = "/assets/redesign/checkout/left-back.png";
const right_form = "/assets/redesign/checkout/right-form.png";
const user_review = "/assets/redesign/checkout/user-review.png";
const back_ticks = "/assets/redesign/icons/back-ticks.png";
const oxxo_icon = "/assets/redesign/icons/oxxo-icon.svg";
const secure_payment = "/assets/redesign/checkout/secure_payment.png";
const stripe_logo = "/assets/redesign/checkout/stripe.png";
const france = "/assets/svg/france.svg";
const usa = "/assets/svg/usa.svg";
declare let Stripe: any;
type ILanguage = "ingles" | "frances" | "ambos" | "";
type TCard = {
	[key: string]: string | number | boolean;
	cvc: number | "";
	number: string;
	date: string;
	holder: string;
	paymentMethodId: string;
};
export const CheckoutV2 = () => {
	const userData = useContext(UserContext);
	const [type, setType] = useState<"mensual" | "anual">("mensual");
	const [currentLanguage, setCurrentLanguage] = useState<ILanguage>("");
	const [paymentMethod, setPaymentMethod] = useState<string>("card");
	const [openLanguagesForResp, setOpenLanguagesForResp] = useState(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingPowerCoach, setLoadingPowerCoach] = useState<boolean>(true);
	const [errors, setErrors] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(false);
	const [message, setMessage] = useState<any>("");
	const [card, setCard] = useState<TCard>({
		cvc: "",
		number: "",
		date: "",
		holder: "",
		paymentMethodId: "",
	});
	const [cards, setCards] = useState<Array<ICard>>([]);
	const [plan, setPlan] = useState({} as IPlan);
	const [powercoach, setPowercoach] = useState({} as powerCoach);
	const [paypal, setPaypal] = useState(true);
	const [selectedTestimony, setSelectedTestimony] = useState<any>(null);
	const [checkBlock, setCheckBlock] = useState<string[]>([]);
	const [spinner, setSpinner] = useState(false);
	const [email, setEmail] = useState("");
	const { currency, handleCurrency } = useUI();
	const [value, setValue] = useState();
	const { handleModalLogin, handleModalRegister } = useUI()

	let english_id = "VoQIZfjMmBS5pXdUwfUhC4d2aot2";
	let french_id = "qFBYxqYXFZhyld4KvM5nzd7Z2ST2";
	const navigate = useNavigate();
	let today = new Date().getTime() / 1000;
	const params: any = useParams();

	const changePaymentMethod = (pm: string) => {
		setPaymentMethod(pm);
		setTimeout(() => {
			handlePaypal();
		}, 100);
	};
	const getPaymentMethods = async () => {
		let tempCards: any = [];
		let data = { customerId: userData.stripe_id };
		const addPm: any = httpsCallable(functions, "customerPaymentMethods_ACC");
		await addPm(data).then(async (res: any) => {
			res.data.data.map((x: any) => {
				tempCards.push({
					paymentMethodId: x.id,
					exp_month: x.card.exp_month,
					exp_year: x.card.exp_year,
					last4: x.card.last4,
					brand: x.card.brand,
				});
			});
			setCards(tempCards);
		});
	};
	const changeLanguage = (language: ILanguage) => {
		setOpenLanguagesForResp(!openLanguagesForResp);
		setCurrentLanguage(language);
		if (language === "ambos") {
			setType("anual");
			navigate(`/academia/${language}/anual`);
		} else {
			navigate(`/academia/${language}/${type}`);
		}
	};
	const changeType = () => {
		if (currentLanguage !== "ambos") {
			if (type === "mensual") {
				setType("anual");
				navigate(`/academia/${currentLanguage}/anual`);
			} else {
				setType("mensual");
				navigate(`/academia/${currentLanguage}/mensual`);
			}
			handlePaypal();
		}
	};
	const goBack = () => {
		navigate("/academia");
	};
	const handlePaypal = () => {
		setPaypal(false);
		setTimeout(() => {
			setPaypal(true);
		}, 200);
	};
	const sendEmail = async (userEmail: string) => {
		const sendEmail = httpsCallable(functions, "brevoSuscriptionEmail");
		try {
			const body = {
				email: userEmail,
				template: 2,
			};
			await sendEmail(body);
		} catch (error) {
			console.log(error);
		}
	};
	const callPowerCoaches = async () => {
		setLoadingPowerCoach(true);
		let id = "";
		if (currentLanguage === "ingles") {
			id = english_id;
		}
		if (currentLanguage === "frances") {
			id = french_id;
		}

		if (currentLanguage === "ambos") {
			let templPlan = { ...plan };

			templPlan.price = (currency !== "usd" ? 2049 : 119.99) * 100;
			templPlan.powerCoachName = "ambos";
			templPlan.planId = currency !== "usd" ? "price_1P3lQSH9ypYniZ9EYlvaWDgW" : "price_1P3lYSH9ypYniZ9EJ4ZKjkyq";
			templPlan.paymentMethod = "card";
			setPlan(templPlan);

			let tempPowercoach: any = {
				id: "ambos",
				paypalPriceId_year: currency !== "usd" ? "P-93Y98298JD206681PMYLPWMY" : "P-1JK02056W7845804WMYK4GNA",
			};
			setPowercoach(tempPowercoach);
		}

		if (userData) {
			getPowerCoachSubByUser(userData.id, id).then((res) => {
				if (res && res.active) {
					// navigate("/academia")
				}
			});
		}

		if (currentLanguage === "ingles" || currentLanguage === "frances") {
			await getPowerCoachById(id).then((res: any) => {
				handlePaypal();
				setPowercoach(res);

				plan.price = currency !== "usd" ? res.precioCard_year : res.precioCard_year_usd;
				plan.powerCoachName = res.name;
				plan.planId = currency !== "usd" ? res.priceId_year : res.priceId_year_usd;
				plan.about = res.about;
				plan.powerCoachPhoto = res.imageLink;
				plan.paymentMethod = "card";
				if (params.idioma === "mensual" || type === "mensual") {
					plan.price = currency !== "usd" ? res.precioCard : res.precioCard_usd;
					plan.planId = currency !== "usd" ? res.priceId : res.priceId_usd;
				} else {
					plan.price = currency !== "usd" ? res.precioCard_year : res.precioCard_year_usd;
					plan.planId = currency !== "usd" ? res.priceId_year : res.priceId_year_usd;
				}
				setPlan(plan);
			});
		}
		// const timeOut = setTimeout(() => {
		// 	setLoadingPowerCoach(false);
		// }, 150);

		// return () => {
		// 	clearTimeout(timeOut);
		// };
	};
	const payOxxo = async () => {
		if (!value) return alert("Ingrese su teléfono");
		if (isValidPhoneNumber(value) === false) return alert("Ingrese un teléfono valido");
		if (!userData) {
			alert("Debe iniciar sesión!");
			return;
		}
		setSpinner(true);
		var stripe = Stripe(
			"pk_live_51IVfzJH9ypYniZ9EQ8OzAj299pTmXs9s1Iw3bVO5aa2GHzZMr6o1tfARiTPPQgfUz6WozWEoNALdvfxoRNIlDB8z00Tfsu1V3b"
		);
		let oxxo = {
			price: plan.price,
			stripeid: userData.stripe_id,
			purchase: {
				cash: plan.price,
				date: new Date(),
				productName: "Suscripción PowerCoach Oxxopay",
				user_name: userData.name,
				user_id: userData.id,
				user_email: userData.email,
				coach_stripe_id: plan.planId,
				payment_method: "Oxxopay",
				purchase_type: "powercoach",
				sub_type: type === "anual" ? "year" : "month",
				powercoachId: powercoach.id,
				phone: value,
			},
		};
		const oxxoPay: any = httpsCallable(functions, "payWithOXXO_ACC");
		await oxxoPay(oxxo).then(async (res: any) => {
			stripe
				.confirmOxxoPayment(res.data.data.client_secret, {
					payment_method: {
						billing_details: {
							name: userData.name + " aprende con cecy",
							email: userData.email,
						},
					},
				})
				.then((res: any) => {
					setSpinner(false);
				});
		});
		return;
	};
	const paySuscription = async () => {
		if (!userData) {
			alert("Debe iniciar sesión!");
			return;
		}
		setIsLoading(true);
		if (!value) {
			alert("Ingrese su teléfono");
			setIsLoading(false);
			return;
		}
		if (isValidPhoneNumber(value) === false) {
			alert("Ingrese un teléfono valido");
			setIsLoading(false);
			return;
		}
		if (Object.keys(card).length === 0 || Object.keys(card).find((key) => card[key] === "" && key !== "paymentMethodId")) {
			setIsLoading(false);
			setErrors(true);
			return;
		}
		setErrors(false);
		let tempSubscription = {
			stripeId: userData.stripe_id,
			price: plan.planId,
			paymentMethodId: "",
			powerCoachId: powercoach.id,
			userName: userData.name,
			userId: userData.id,
			userEmail: userData.email,
			phone: value,
		};
		if (!card.paymentMethodId) {
			let addCard = {
				cardData: {
					number: card.number,
					exp_month: card.date.substring(0, 2),
					exp_year: card.date.substring(3, 5),
					cvc: card.cvc,
				},
				userId: userData.stripe_id,
			};

			const addPm: any = httpsCallable(functions, "newCard_ACC");
			await addPm(addCard).then(async (res: any) => {
				if (res.data.error) {
					cardErrors(res).then((response: any) => {
						setMessage(response);
						setShow(true);
						setIsLoading(false);
					});
				} else {
					let tempCard = {
						brand: res.data.data.card.brand,
						exp_month: res.data.data.card.exp_month,
						exp_year: res.data.data.card.exp_year,
						last4: res.data.data.card.last4,
						name: userData.name,
						stripe_id: res.data.data.id,
					};
					addPaymentMethod(userData.id, res.data.data.id, tempCard).then(async () => {
						const subscription: any = httpsCallable(functions, "createSubscription_ACC");
						tempSubscription.paymentMethodId = tempCard.stripe_id;
						await subscription(tempSubscription).then((res: any) => {
							if (res.data.error) {
								alert("Ocurrio un error");
								setIsLoading(false);
								return;
							}
							if (res.data.subscription.status !== "incomplete") {
								localStorage.setItem("language", currentLanguage === "ingles" ? "english" : "french");
								sendEmail(userData.email);
								navigate("/thankyou");
								setSuccess(true);
								setIsLoading(false);
							} else {
								setIsLoading(false);
								setMessage("Ha ocurrido un error");
								setShow(true);
							}
						});
					});
				}
			});
		} else {
			const subscription: any = httpsCallable(functions, "createSubscription_ACC");
			tempSubscription.paymentMethodId = card.paymentMethodId;
			await subscription(tempSubscription).then((res: any) => {
				if (res.data.error) {
					alert("Ocurrio un error");
					setIsLoading(false);
					return;
				}
				if (res.data.subscription.status !== "incomplete") {
					localStorage.setItem("language", currentLanguage === "ingles" ? "english" : "french");
					sendEmail(userData.email);
					navigate("/thankyou");
					setSuccess(true);
					setIsLoading(false);
				} else {
					setIsLoading(false);
					setMessage("Ha ocurrido un error");
					setShow(true);
				}
			});
		}
	};

	const payWithoutUser = async () => {
		setIsLoading(true);
		if (!value) {
			alert("Ingrese su teléfono");
			setIsLoading(false);
			return;
		}
		if (isValidPhoneNumber(value) === false) {
			alert("Ingrese un teléfono valido");
			setIsLoading(false);
			return;
		}
		if (!userData) {
			alert("Debe iniciar sesión!");
			return;
		}
		if (Object.keys(card).length === 0 || Object.keys(card).find((key) => card[key] === "" && key !== "paymentMethodId")) {
			setIsLoading(false);
			setErrors(true);
			return;
		}
		let user: any = await getUserByEmail(email);
		const sub = {
			stripe_id: "",
			final_date: null,
			initial_date: null,
			active: false,
			firstTimeSub: false,
			cancel_at_period_end: type === "anual" ? true : false,
		};
		if (!user) {
			let tempUser = {
				name: "",
				email: email,
				birthdate: "",
				photo: "/assets/icons/avatar.png",
				coverPhoto: "",
				stripe_id: "",
				userType: 1,
				renovation: true,
			};
			const userId = await createUserCollection(tempUser);
			await updateUserPowerCoachSubscription(userId, powercoach.id, sub);
			let userCollection: any = "";

			setTimeout(async () => {
				userCollection = await getUser(userId);
				if (!card.paymentMethodId) {
					let addCard = {
						cardData: {
							number: card.number,
							exp_month: card.date.substring(0, 2),
							exp_year: card.date.substring(3, 5),
							cvc: card.cvc,
						},
						userId: userCollection.stripe_id,
					};
					const addPm: any = httpsCallable(functions, "newCard_ACC");
					await addPm(addCard).then(async (res: any) => {
						if (res.data.error) {
							cardErrors(res).then((response: any) => {
								setMessage(response);
								setShow(true);
								setIsLoading(false);
							});
						} else {
							let tempSubscription = {
								stripeId: userCollection.stripe_id,
								price: plan.planId,
								paymentMethodId: res.data.data.id,
								powerCoachId: powercoach.id,
								userName: email,
								userId: userId,
								userEmail: email,
								phone: value,
							};

							const subscription: any = httpsCallable(functions, "createSubscription_ACC");
							await subscription(tempSubscription).then((res: any) => {
								if (res.data.error) {
									alert("Ocurrio un error");
									setIsLoading(false);
									return;
								}
								if (res.data.subscription.status !== "incomplete") {
									navigate("/thankyou");
									sendEmail(tempUser.email);
									setSuccess(true);
									setIsLoading(false);
								} else {
									setIsLoading(false);
									setMessage("Ha ocurrido un error");
									setShow(true);
								}
							});
						}
					});
				}
			}, 2000);
		} else {
			if (!card.paymentMethodId) {
				let addCard = {
					cardData: {
						number: card.number,
						exp_month: card.date.substring(0, 2),
						exp_year: card.date.substring(3, 5),
						cvc: card.cvc,
					},
					userId: user.stripe_id,
				};
				const addPm: any = httpsCallable(functions, "newCard_ACC");
				await addPm(addCard).then(async (res: any) => {
					if (res.data.error) {
						cardErrors(res).then((response: any) => {
							setMessage(response);
							setShow(true);
							setIsLoading(false);
						});
					} else {
						let tempSubscription = {
							stripeId: user.stripe_id,
							price: plan.planId,
							paymentMethodId: res.data.data.id,
							powerCoachId: powercoach.id,
							userName: email,
							userId: user.id,
							userEmail: email,
							phone: value,
						};
						await updateUserPowerCoachSubscription(user.id, powercoach.id, sub);
						const subscription: any = httpsCallable(functions, "createSubscription_ACC");
						await subscription(tempSubscription).then((res: any) => {
							if (res.data.error) {
								alert("Ocurrio un error");
								setIsLoading(false);
								return;
							}
							if (res.data.subscription.status !== "incomplete") {
								sendEmail(email);
								navigate("/thankyou");
								setSuccess(true);
								setIsLoading(false);
							} else {
								setIsLoading(false);
								setMessage("Ha ocurrido un error");
								setShow(true);
							}
						});
					}
				});
			}
		}
	};

	const getSubscriptions = async () => {
		const subs = await retrieveUserSubs(userData.id);
		const checkSubs = subs.filter((x: any) => x.final_date !== null && x.final_date.seconds > today);
		if (checkSubs.length === 2) {
			navigate(`/`);
			return;
		}
		if (checkSubs.length > 0) {
			let retreiveEnglish = checkSubs.filter((x: any) => x.id === english_id);
			let retreiveFrench = checkSubs.filter((x: any) => x.id === french_id);
			let tempBlock: string[] = [];
			if (retreiveEnglish.length > 0) {
				tempBlock.push("ingles");
				navigate(`/academia/frances/${params.tipo}`);
				setCurrentLanguage("frances");
			}
			if (retreiveFrench.length > 0) {
				tempBlock.push("frances");
				navigate(`/academia/ingles/${params.tipo}`);
				setCurrentLanguage("ingles");
			}
			setCheckBlock(tempBlock);
		}
	};

	useEffect(() => {
		callPowerCoaches();
	}, [currentLanguage, type, currency]);

	useEffect(() => {
		if (userData) {
			getPaymentMethods();
			getSubscriptions();
		}
	}, [userData]);

	useEffect(() => {
		if (params.idioma === "ingles" || params.idioma === "frances" || params.idioma === "ambos") {
			if (params.idioma === "ambos") {
				setCurrentLanguage(params.idioma);
				setType("anual");
				navigate(`/academia/ambos/anual`);
			} else {
				setCurrentLanguage(params.idioma);
				if (params.tipo === "mensual" || params.tipo === "anual") {
					setType(params.tipo);
					navigate(`/academia/${params.idioma}/${params.tipo}`);
				} else {
					setType("mensual");
					navigate(`/academia/${params.idioma}/mensual`);
				}
			}
		} else {
			setCurrentLanguage("ingles");
			if (params.tipo !== "mensual" || params.tipo !== "anual") {
				setType(params.tipo);
				navigate(`/academia/ingles/${params.tipo}`);
			} else {
				setType("mensual");
				navigate(`/academia/ingles/mensual`);
			}
		}
		const random = Math.random();
		const round = Math.ceil(random * 8) - 1;
		if (round > -1 && round < 8) {
			setSelectedTestimony(testimonies_array[round]);
		} else {
			setSelectedTestimony(testimonies_array[round]);
		}
	}, []);

	useEffect(() => {
		if (powercoach && plan) {
			setLoadingPowerCoach(false);
		}
	}, [powercoach, plan]);
	return (
		<div className="checkout-v2-container">
			<img src={left_form} className="left-form" />
			<img src={left_back} className="left-back" />
			<img src={right_form} className="right-form" />
			<div className="left-side size-resp">
				<h2 className="announce">¡13 DE MAYO ÚLTIMO DÍA!</h2>
				<div className="header">
					<div className="d-flex align-items-center gap-3">
						<IoArrowBackOutline onClick={goBack} />
						<h2>Detalles de pago</h2>
					</div>
					<div className="currency-selector-container">
						<label htmlFor="currency">Precios en:</label>
						<select name="currency" id="currency" value={currency} onChange={(e) => handleCurrency(e.target.value)}>
							<option value="mxn">MXN</option>
							<option value="usd">USD</option>
						</select>
					</div>
				</div>
				<div className="data-container ">
					{type === "mensual" && (
						<p>*Tu suscripción continuará renovándose automáticamente cada mes a menos que decidas cancelarla. </p>
					)}
					{type === "anual" && currentLanguage === "ambos" && (
						<p>
							*Gracias por elegir nuestro servicio de suscripción anual. Con este plan realizas un único pago que cubre tu
							membresía durante el año. Al acercarse el final del período de tu suscripción, recibirás un aviso para
							informarte sobre la próxima renovación.
						</p>
					)}
					{type === "anual" && currentLanguage !== "ambos" && (
						<p>
							*Gracias por elegir nuestro servicio de suscripción semestral. Con este plan realizas un único pago que cubre
							tu membresía durante seis meses. Al acercarse el final del período de tu suscripción, recibirás un aviso para
							informarte sobre la próxima renovación.
						</p>
					)}
					<p>
						Para cancelar una cuenta, es necesario solicitar la cancelación al servicio al cliente dentro de un plazo de 72
						horas. Después de este período, no se aceptarán solicitudes de reembolso por cancelaciones.
					</p>
					<p>Complete su compra ingresando un método de pago</p>
					<div className="row-input payment-methods">
						<div
							className={"payment-container " + (paymentMethod === "card" ? "payment-selected" : "")}
							onClick={() => changePaymentMethod("card")}
						>
							<CiCreditCard1 />
							<p>Pagar con tarjeta</p>
							<div className="circle">
								<div className="inner-circle" />
							</div>
						</div>
						{/* {userData && (
						)} */}
							<div
								className={"payment-container " + (paymentMethod === "paypal" ? "payment-selected" : "")}
								onClick={() => changePaymentMethod("paypal")}
							>
								<TbBrandPaypal />
								<p>Pagar con PayPal</p>
								<div className="circle">
									<div className="inner-circle" />
								</div>
							</div>

						{userData && (
							<div
								className={"payment-container " + (paymentMethod === "oxxo" ? "payment-selected" : "")}
								onClick={() => changePaymentMethod("oxxo")}
							>
								<img src={oxxo_icon} />
								<p>Pagar con Oxxo</p>
								<div className="circle">
									<div className="inner-circle" />
								</div>
							</div>
						)}
					</div>
					{(paymentMethod === "oxxo" || paymentMethod === "card") && (
						<div className="input-container">
							<PhoneInput
								className="phone-input"
								placeholder="Ingrese su número de teléfono"
								value={value}
								onChange={(e: any) => {
									setValue(e);
								}}
							/>
							{errors && !value && <p className="error">Agregue su teléfono</p>}
						</div>
					)}
					<div className={"inputs " + (paymentMethod !== "card" ? "not-card" : " ")}>
						{userData === null && (
							<div className="input-container">
								<p className="title">Correo electronico</p>
								<input
									className="input"
									type="email"
									placeholder="example@hotmail.com"
									onChange={(e) => setEmail(e.target.value)}
								/>
								{errors && !email && <p className="error">Agregue su correo electronico</p>}
							</div>
						)}
						<div className="input-container first">
							<p className="title">Número en la tarjeta</p>
							<ReactInputMask
								className="input"
								placeholder="Número de tarjeta"
								mask="9999 9999 9999 9999"
								maskChar={null}
								onChange={(e) => setCard({ ...card, number: e.target.value })}
							/>
							{errors && card.number === "" && <p className="error">Agregue el numero de tarjeta</p>}
						</div>
						<div className="row-input second">
							<div className="input-container">
								<p className="title">Fecha de expiración</p>
								<ReactInputMask
									className="input"
									placeholder=" 12 / 28"
									mask="99/99"
									maskChar={null}
									onChange={(e) => setCard({ ...card, date: e.target.value })}
								/>
								{errors && card.date === "" && <p className="error">Agregue la fecha</p>}
							</div>
							<div className="input-container">
								<p className="title">CCV</p>
								<input
									className="input"
									type="password"
									maxLength={4}
									placeholder="CVV"
									onChange={(e) => setCard({ ...card, cvc: parseInt(e.target.value) })}
								/>
								{errors && card.cvc === "" && <p className="error">Agregue el cvv</p>}
							</div>
						</div>
						<div className="input-container third">
							<p className="title">Nombre en la tarjeta</p>
							<input
								className="input"
								placeholder="Nombre en la tarjeta"
								onChange={(e) => setCard({ ...card, holder: e.target.value })}
							/>
							{errors && card.holder === "" && <p className="error">Agregue el nombre en la tarjeta</p>}
						</div>
					</div>
					<div className="bottom-data">
						<div className="row-data plan-selected">
							<p>Plan Seleccionado</p>
							<p className="type">{returnPlan(currentLanguage, type)}</p>
						</div>
						<div className="row-data">
							<p>Subtotal</p>
							<p>
								${plan.price ? (plan.price * (currency !== "usd" ? 0.84 : 1)) / 100 : 0} {currency.toUpperCase()}
							</p>
						</div>
						{currency !== "usd" && (
							<div className="row-data">
								<p>IVA</p>
								<p>
									${plan.price ? ((plan.price * 0.16) / 100).toFixed(2) : 0} {currency.toUpperCase()}
								</p>
							</div>
						)}
						<div className="row-data total">
							<p>Total</p>
							<p>
								${plan.price ? plan.price / 100 : 0} {currency.toUpperCase()}
							</p>
						</div>
						{paymentMethod === "card" && (
							<>
								{isLoading ? (
									<Spinner width={0.3} size={3} />
								) : (
									<button type="submit" onClick={userData !== null ? paySuscription : payWithoutUser}>
										Pagar ${plan.price ? plan.price / 100 : 0} {currency.toUpperCase()}
									</button>
								)}
							</>
						)}
						<div className="paypal-button">
							{paymentMethod === "paypal" && paypal && powercoach && !loadingPowerCoach && (
								<PayPalScriptProvider
									options={{
										"client-id": "AXn-e1UCYo0EoE0WZTj-_1KRHkk0xEA-ei_XWLYDWAq0qmfaIC_6LYdDMB0DNOEsfisf5A5G80_MxIbn",
										currency: "MXN",
										vault: true,
										intent: "subscription",
									}}
								>
									<button className="paypal" type="submit">
										<PayPalButtons
											className="paypal"
											style={{
												color: "gold",
												layout: "horizontal",
												height: 55,
												shape: "pill",
												// label: "subscribe",
												// tagline: false,
											}}
											onClick={() => {
												if (userData === null) {
													// navigate("/login");
													handleModalRegister();
													navigate("/academia");
													return;
												}
											}}
											createSubscription={(data, actions) => {
												const paypalId =
													currentLanguage === "ambos"
														? powercoach.paypalPriceId_year
														: type === "anual"
														? currency === "usd"
															? powercoach.paypalPriceId_year_usd
															: powercoach.paypalPriceId_year
														: currency === "usd"
														? powercoach.paypalPriceId_usd
														: powercoach.paypalPriceId;
												return actions.subscription.create({
													plan_id: paypalId,
												});
											}}
											onApprove={async (data: any, actions) => {
												let final_date = new Date();
												let sub = {
													sub_type: type === "anual" ? "year" : "month",
													stripe_id: data.subscriptionID,
													final_date:
														type === "anual"
															? new Date(final_date.setDate(final_date.getDate() + 365))
															: new Date(final_date.setDate(final_date.getDate() + 30)),
													cancel_at_period_end: type === "anual" ? true : false,
												};
												let tempUser: any = {
													paypalEmail: userData.email,
													paypalSub: [],
													id: userData.id,
												};
												if (userData.paypalSub && userData.paypalSub.length > 0) {
													tempUser.paypalSub.push(data.subscriptionID);
												} else {
													tempUser.paypalSub = [userData.paypalSub, data.subscriptionID];
												}
												await updatePowerCoachCount(powercoach);
												updateUserInfo(tempUser);
												updateUserPowerCoachSubscription(userData.id, powercoach.id, sub);
												// setSuccess(true);
												sendEmail(userData.email);
												navigate("/thankyou");
												return data;
											}}
										/>
									</button>
								</PayPalScriptProvider>
							)}
						</div>

						{paymentMethod === "oxxo" && (
							<button className="oxxo" type="submit" onClick={payOxxo}>
								{!spinner ? <span>Oxxo pay</span> : <Spinner width={0.3} size={3} />}
							</button>
						)}
						<div className="pictures-row">
							<img src={secure_payment} className="secure" />
							<img src={stripe_logo} className="stripe" />
						</div>
					</div>
				</div>
			</div>
			<div className="right-side size-resp">
				<div className={"top-contain " + (openLanguagesForResp ? "open-language" : "")}>
					<div className="go-back" onClick={() => setOpenLanguagesForResp(false)}>
						<IoArrowBackOutline />
						<p>Regresar</p>
					</div>
					<div className="languages">
						<div
							className={
								"single-language " +
								(currentLanguage === "ingles" ? "selected-language " : "order-unsel ") +
								(checkBlock.filter((x: string) => x === "ingles").length === 0 ? "" : " disabled-option")
							}
							onClick={() =>
								checkBlock.filter((x: string) => x === "ingles").length === 0 ? changeLanguage("ingles") : ""
							}
						>
							<div className="img-contain">
								<img src={usa} />
							</div>
							<h2>Inglés</h2>
						</div>
						<div
							className={
								"single-language " +
								(currentLanguage === "frances" ? "selected-language" : "order-unsel") +
								(checkBlock.filter((x: string) => x === "frances").length === 0 ? "" : " disabled-option")
							}
							onClick={() =>
								checkBlock.filter((x: string) => x === "frances").length === 0 ? changeLanguage("frances") : ""
							}
						>
							<div className="img-contain">
								<img src={france} />
							</div>
							<h2>Francés</h2>
						</div>
						<div
							className={
								"single-language " +
								(currentLanguage === "ambos" ? "selected-language" : "order-unsel") +
								(checkBlock.length === 0 ? "" : " disabled-option")
							}
							onClick={() => (checkBlock.length === 0 ? changeLanguage("ambos") : "")}
						>
							<div className="img-contain">
								<img src={usa} />
							</div>
							<div className="img-contain in-both">
								<img src={france} />
							</div>
							<h2>Ambos</h2>
						</div>
					</div>
					<div className={"select-type " + (currentLanguage === "ambos" ? "select-type-both" : "")}>
						<p>Mensual</p>
						<div className={"input " + (type === "anual" ? "anual" : "mensual")} onClick={changeType}>
							<div className="dot" />
						</div>
						<p>{currentLanguage === "ambos" ? "Anual" : "Semestral"}</p>
					</div>
				</div>
				<div className="bottom-contain norm-bottom">
					<div className="row-data">
						<img src={back_ticks} className="back-ticks" />
						<img src={selectedTestimony !== null ? selectedTestimony.img : user_review} className="user" />
					</div>
					<p className="about">{selectedTestimony !== null && selectedTestimony.about}</p>
					<div className="row-data">
						<p className="name">{selectedTestimony !== null && selectedTestimony.name}</p>
						<img src={back_ticks} className="back-ticks rotation" />
					</div>
				</div>
			</div>
			<div className="bottom-contain resp-bottom size-resp">
				<div className="row-data">
					<img src={back_ticks} className="back-ticks" />
					<img src={selectedTestimony !== null ? selectedTestimony.img : user_review} className="user" />
				</div>
				<p className="about">{selectedTestimony !== null && selectedTestimony.about}</p>
				<div className="row-data">
					<p className="name">{selectedTestimony !== null && selectedTestimony.name}</p>
					<img src={back_ticks} className="back-ticks rotation" />
				</div>
			</div>
			{/* <SuccessModal
                success={success}
            /> */}
			<AlertModal
				title="Error de pago"
				message={message}
				show={show}
				onHide={() => {
					setShow(false);
				}}
			/>
		</div>
	);
};
